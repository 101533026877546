<template>
  <footer class="footer">
    <div class="footer__container typography__body1">
      <ul class="footer__content">
        <li>
          <BaseLink
            class="footer__link"
            href="/"
          >
            <BaseIcon2
              class="footer__link"
              height="24"
              viewBox="0 0 125 24"
              width="125"
            >
              <g
                clip-path="url(#a)"
                fill="#bababa"
              >
                <path
                  d="m44 2-6-2-6 2-4 4-2 6 2 6a12 12 0 0 0 10 6 12 12 0 0 0 12-12 12 12 0 0 0-6-10ZM104 0v11h6v13h10V11h5V0h-21ZM68 10 63 0H53v24h8V13l5 11h10V0h-8v10ZM92 14l-1 1a1 1 0 0 1-1 0h-1V0H79v11l1 7 4 4a12 12 0 0 0 15-2l2-2 1-3V0H92v14ZM18 2l-6-2H0v24h11l7-2 5-4 1-6-1-6-5-4Zm-5 12-3 1V9h1l2 1 1 2-1 2Z"
                />
              </g>

              <defs>
                <clipPath id="a">
                  <path
                    d="M0 0h125v24H0z"
                    fill="#fff"
                  />
                </clipPath>
              </defs>
            </BaseIcon2>
          </BaseLink>
        </li>

        <li>
          <BaseLink
            class="footer__content-link"
            href="mailto:sayhi@mydonut.app"
          >
            sayhi@mydonut.app
          </BaseLink>
        </li>

        <li class="footer__socials">
          <template
            v-for="(social, socialIndex) in SOCIALS"
            :key="socialIndex"
          >
            <BaseLink
              class="footer__socials-item"
              :href="social.href"
            >
              <BaseIcon2
                :id="social.icon"
                height="32"
                sprite="socials"
                width="32"
              />
            </BaseLink>
          </template>
        </li>
      </ul>

      <ul class="footer__content typography__body1">
        <li
          v-for="(link, linkIndex) in LINKS"
          :key="linkIndex"
        >
          <BaseLink
            class="footer__content-link"
            :href="link.href"
          >
            {{ link.text }}
          </BaseLink>
        </li>
      </ul>

      <ul class="footer__content typography__body1">
        <li
          v-for="(term, termIndex) in TERMS"
          :key="termIndex"
        >
          <BaseLink
            class="footer__content-term"
            :href="term.href"
          >
            {{ term.text }}
          </BaseLink>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script setup lang="ts">
// TODO: Ссылки пока пустые. Добавить когда появятся соц.сети
const SOCIALS = [
  {
    href: "/",
    icon: "icon-whatsapp-flat",
  },
  {
    href: "/",
    icon: "icon-telegram-flat",
  },
  {
    href: "/",
    icon: "icon-instagram-flat",
  },
];

const LINKS = [
  {
    href: "/rates",
    text: "Тарифы",
  },
  {
    href: "/questions",
    text: "Поддержка",
  },
  {
    href: "/contacts",
    text: "Контакты",
  },
];

const TERMS = [
  {
    href: "/about/user-agreement",
    text: "Пользовательское соглашение",
  },
  {
    href: "/about/privacy-policy",
    text: "Политика конфиденциальности",
  },
  {
    href: "/about/cookie",
    text: "Использование cookie",
  },
  {
    href: "/about/license-agreement",
    text: "Лицензионный договор",
  },
  {
    href: "/",
    text: `© ${new Date().getFullYear()} Donut`,
  },
];
</script>

<style scoped>
.footer__container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 1206px;
  margin: 0 auto;
  padding: 40px 24px;
}

@media (--desktop) {
  .footer__container {
    padding-left: 0;
    padding-right: 0;
  }
}

.footer__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (--desktop) {
  .footer__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.footer__link {
  display: block;
}

.footer__content-link {
  color: var(--grey-1-5);
}

@media (--hover) {
  .footer__content-link:hover {
    color: var(--orange-3);
  }
}

.footer__socials {
  display: flex;
  column-gap: 19px;
  color: var(--white);
}

.footer__socials-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--white);
  border-radius: 50%;
  background-color: var(--grey-3);
}

@media (--hover) {
  .footer__socials-item:hover {
    background-color: var(--grey-2);
  }
}

.footer__content-term {
  color: var(--grey-3);
}

.footer__content li:last-child .footer__content-term {
  color: var(--orange-1);
}

@media (--hover) {
  .footer__content-term:hover {
    color: var(--grey-2);
  }
}
</style>
