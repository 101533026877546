<template>
  <header class="header">
    <div
      class="header__container"
      :class="{ active: isPossibilitiesDropDownOpen }"
    >
      <nav>
        <ul class="header__links">
          <li class="header__item">
            <BaseLink href="/">
              <img
                alt=""
                class="header__logo"
                height="19"
                src="/logos/logo.svg"
                width="94"
              />
            </BaseLink>
          </li>

          <li class="header__item">
            <BaseDropdown2
              id="clientPossibilitiesDropdown"
              ref="clientPossibilitiesDropdown"
              :offset-x="-1400"
              @close="() => (isPossibilitiesDropDownOpen = false)"
              @open="() => (isPossibilitiesDropDownOpen = true)"
            >
              <template #reference>
                <BaseButton2
                  class="header__link"
                  :class="{ active: isPossibilitiesDropDownOpen }"
                >
                  Возможности

                  <BaseIcon2
                    id="icon-cap"
                    class="header__link-caret"
                    :class="{ active: isPossibilitiesDropDownOpen }"
                    height="16"
                    width="16"
                  />
                </BaseButton2>
              </template>

              <template #content>
                <ul class="dropdown__list">
                  <li
                    v-for="(accordion, accordionIndex) in accordionList"
                    :key="accordionIndex"
                  >
                    <BaseButton2
                      class="dropdown__item"
                      @click="() => closeDropdownList(accordion.href)"
                    >
                      <h4
                        class="typography__title3"
                        v-html="accordion.name"
                      />

                      <p
                        class="typography__label1"
                        v-html="accordion.description"
                      />
                    </BaseButton2>
                  </li>
                </ul>
              </template>
            </BaseDropdown2>
          </li>

          <li
            v-for="(link, linkIndex) in menu"
            :key="linkIndex"
            class="header__item"
          >
            <BaseLink
              class="header__link"
              :href="link.href"
            >
              {{ link.name }}
            </BaseLink>
          </li>
        </ul>
      </nav>

      <div class="header__tools-container">
        <TheHeaderTools />

        <BaseButton2
          class="header__burger"
          @click="() => openMenu()"
        >
          <BaseIcon2
            id="icon-burger"
            height="24"
            width="24"
          />
        </BaseButton2>
      </div>
    </div>

    <TheMenuMobile
      :accordionList
      class="ui-scrollbar-primary"
      :menu
    />
  </header>
</template>

<script setup lang="ts">
const accordionList = [
  {
    description: "Управляйте индивидуальными и&nbsp;групповыми записями всех филиалов через журнал",
    href: "/features/online-record",
    name: "Онлайн-запись",
  },
  {
    description: "Продавайте товары в&nbsp;удобном каталоге с&nbsp;гибкими настройками",
    href: "/features/shop",
    name: "Интернет-магазин",
  },
  {
    description: "Предлагайте клиентам любые услуги с&nbsp;быстрой отправкой заявки",
    href: "/features/services-with-applications",
    name: "Услуги с заявками",
  },
  {
    description: "Пользуйтесь понятной и&nbsp;функциональной системой управления",
    href: "/features/crm",
    name: "Эффективная CRM",
  },
  {
    description: "Общайтесь с&nbsp;клиентами из&nbsp;разных мессенджеров в&nbsp;CRM",
    href: "/features/chats",
    name: "Чаты с клиентами",
  },
  {
    description: "Добавьте фирменный цвет и&nbsp;нужную информацию о&nbsp;компании",
    href: "/features/website",
    name: "Конструктор сайта",
  },
  {
    description: "Настраивайте уведомления и&nbsp;автоматические ответы клиенту",
    href: "/features/bot",
    name: "Конструктор бота",
  },
  {
    description: "История всех заказов, записей и&nbsp;заявок в&nbsp;одном удобном профиле",
    href: "/features/client-cabinet",
    name: "Личный кабинет клиента",
  },
];

const menu = [
  {
    href: "/#examples",
    name: "Примеры",
  },
  {
    href: "/rates",
    name: "Тарифы",
  },
  {
    href: "/questions",
    name: "Поддержка",
  },
];

const { toggleMenu } = useAppStore();

const openMenu = () => {
  toggleMenu(true);
  pageScrollBlock();
};

const clientPossibilitiesDropdown = ref<HTMLElement[] | null>(null);
const isPossibilitiesDropDownOpen = ref(false);

const closeDropdownList = async (href: string) => {
  if (clientPossibilitiesDropdown.value) {
    clientPossibilitiesDropdown.value.closeDropdown();
  }

  await navigateTo(href);
};
</script>

<style scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 2;
  padding-top: 16px;
}

.header__container {
  position: relative;
  z-index: var(--z-index-header);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1286px;
  height: 48px;
  margin: 0 auto;
  padding: 4px 12px 4px 24px;
  border-radius: 50px;
  background-color: var(--white);
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 8%);
}

@media (--desktop) {
  .header__container {
    min-width: 1036px;
    height: 68px;
    padding: 0 40px;
  }

  .header__container.active {
    border-radius: 34px 34px 0 0;
  }
}

@media (--desktop) {
  .header__logo {
    margin-right: 24px;
    width: 104px;
    height: 20px;
  }
}

.header__links,
.header__item {
  display: flex;
  align-items: center;
}

.header__link {
  display: none;
}

@media (--desktop) {
  .header__link {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 8px 24px;
  }

  .header__link.active {
    color: var(--orange-3);
  }
}

.header__link-caret {
  rotate: 90deg;
  color: var(--grey-3);
}

.header__link-caret.active {
  rotate: -90deg;
}

@media (--hover) {
  .header__link:hover,
  .header__link:active {
    color: var(--orange-3);
  }
}

.dropdown__list {
  position: relative;
  top: 15px;
  left: -170px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
  max-width: 1286px;
  min-width: 1036px;
  padding: 0 24px 16px;
  border-radius: 0 0 34px 34px;
  background-color: var(--white);
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 8%);
}

.dropdown__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  padding: 16px;
  text-align: left;
  border-radius: 16px;
}

@media (--hover) {
  .dropdown__item:hover,
  .dropdown__item:active {
    background-color: var(--grey-7);
  }
}

.header__tools-container {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.header__burger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: var(--grey-3);
}

@media (--desktop) {
  .header__burger {
    display: none;
  }
}
</style>
