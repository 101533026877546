<template>
  <div
    class="the-menu-overlay"
    :class="{ open: isMenuVisible }"
    @mousedown.self="() => closeMenu()"
  >
    <aside class="the-menu ui-scrollbar-primary">
      <div class="the-menu__header">
        <BaseLink href="/">
          <img
            alt=""
            class="the-menu__logo"
            height="19"
            src="/logos/logo.svg"
            width="94"
          />
        </BaseLink>

        <BaseButton2
          class="the-menu__close"
          @click="() => closeMenu()"
        >
          <BaseIcon2
            id="icon-cross"
            color="var(--grey-3)"
            height="16"
            width="16"
          />
        </BaseButton2>
      </div>

      <nav class="the-menu__navigation">
        <BaseAccordion class="the-menu__navigation-accordion ui-accordion-primary">
          <template #title>
            <span class="the-menu__item-accordion">Возможности</span>
          </template>

          <template #content>
            <ul class="accordion__list">
              <li
                v-for="(accordion, accordionIndex) in accordionList"
                :key="accordionIndex"
              >
                <BaseLink
                  class="accordion__item typography__label1"
                  :class="{ active: route.path.startsWith(accordion.href) }"
                  :href="accordion.href"
                  @click="() => closeMenu()"
                >
                  {{ accordion.name }}
                </BaseLink>
              </li>
            </ul>
          </template>
        </BaseAccordion>

        <ul class="the-menu__list">
          <li
            v-for="(element, elementIndex) in menu"
            :key="elementIndex"
          >
            <BaseLink
              class="the-menu__item"
              :class="{ active: route.path.startsWith(element.href) }"
              :href="element.href"
              @click="() => closeMenu()"
            >
              {{ element.name }}
            </BaseLink>
          </li>
        </ul>
      </nav>

      <TheHeaderTools is-mobile-menu />
    </aside>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  accordionList: object;
  menu: object;
}

defineProps<Props>();

const { isMenuVisible, toggleMenu } = useAppStore();
const route = useRoute();

const closeMenu = () => {
  toggleMenu(false);
  pageScrollUnblock();
};
</script>

<style scoped>
.the-menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: var(--z-index-header);
  display: none;
  overflow-y: auto;
}

.the-menu-overlay.open {
  display: flex;
}

.the-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 24px;
  row-gap: 16px;
  padding: 4px 24px 24px;
  height: min-content;
  margin-top: 12px;
  margin-bottom: 12px;
  overflow-y: auto;
  background-color: var(--white);
  max-height: calc(100vh - 24px);
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 8%);
}

@media (--desktop) {
  .the-menu {
    padding: 14px 40px 24px;
    box-shadow: 0 3px 16px 0 rgb(0 0 0 / 8%);
  }
}

@media (--desktop) {
  .the-menu__logo {
    width: 104px;
    height: 20px;
  }
}

.the-menu__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.the-menu__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.the-menu__navigation {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.the-menu__list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.the-menu__item {
  padding: 8px 0;
  justify-content: flex-start;
  width: 100%;
}

.the-menu__navigation-accordion.open .the-menu__item-accordion,
.accordion__item.active,
.the-menu__item.active {
  color: var(--orange-3);
}

:deep(.the-menu__navigation-accordion .c-accordion__button) {
  justify-content: flex-start;
  column-gap: 4px;
}

:deep(.the-menu__navigation-accordion) {
  row-gap: 0;
}

.the-menu__item-accordion {
  padding: 8px 0;
}

.accordion__list {
  display: flex;
  flex-direction: column;
}

.accordion__item {
  color: var(--grey-1);
  padding: 8px 0;
}
</style>
